<template>
  <div class="body-box">
    <!--    导航盒子-->
    <nav-box></nav-box>
    <el-card class="box-card">
      <!-- logo部分 -->
      <div>
        <img src="" alt=""/>
        <span>欢迎注册!</span>
      </div>

      <div class="prompt">短信验证码验证通过以后，账号立即生成。登陆账号是登录手机号，可以从首页进行登录，也可以选择继续完善信息。有疑问请拨打: 0874-5692871</div>

      <!-- 步骤条 -->
      <el-steps :active="activeIndex" finish-status="success" align-center>
        <el-step title="注册用户"></el-step>
        <el-step title="填写信息"></el-step>
        <el-step title="提交完成"></el-step>
      </el-steps>

      <el-form
        ref="addFormRef"
        :model="basicsForm"
        :rules="rules"
        label-width="140px"
      >
        <el-row class="form-box">
          <el-col :span="12">
            <el-form-item label="公司名称" prop="name">
              <el-input v-model="basicsForm.name" placeholder="请输入公司名称">
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="公司法人" prop="contacts">
              <el-input v-model="basicsForm.contacts" placeholder="请输入公司法人">
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="公司业务联系人" prop="contactPerson">
              <el-input v-model="basicsForm.contactPerson" placeholder="请输入公司业务联系人"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="业务联系人电话" prop="phone">
              <el-input v-model="basicsForm.phone" placeholder="请输入业务联系人电话"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="公司主管联系人" prop="contactPerson2">
              <el-input v-model="basicsForm.contactPerson2" placeholder="请输入公司主管联系人"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="主管联系人电话" prop="phone2">
              <el-input v-model="basicsForm.phone2" placeholder="请输入主管联系人电话"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="公司邮箱" prop="mailbox">
              <el-input v-model="basicsForm.mailbox" placeholder="请输入公司邮箱"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="统一社会信用代码" prop="mailbox">
              <el-input v-model="basicsForm.socialCode" placeholder="请输入统一社会信用代码"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="注册资本(万元)" prop="capital">
              <el-input v-model="basicsForm.capital" placeholder="请输入注册资本"  oninput="value=value.replace(/[^\d\.]/g,'')"/>
            </el-form-item>
          </el-col>

          <el-col :span="12" class="select-box">
            <el-form-item label="供应商类型" prop="enumType">
              <el-select v-model="basicsForm.enumType" placeholder="请选择供应商类型">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="公司地址" prop="address">
              <el-input v-model="basicsForm.address" placeholder="请输入详细地址"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="股票代码">
              <el-input v-model="basicsForm.stock" placeholder="请输入股票代码">
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="营业执照有效期起" prop="addvalidity">
              <el-date-picker
                v-model="basicsForm.addvalidity"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="营业执照有效期至" prop="editvalidity">
              <el-date-picker
                v-model="basicsForm.editvalidity"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="永久请选择2099年后任意时间"
                :picker-options="editValidityOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="银行开户户头" prop="bank">
              <el-input v-model="basicsForm.bank" placeholder="请输入银行开户户头"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="银行账号" prop="account">
              <el-input v-model="basicsForm.account" placeholder="请输入银行账号"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="完整开户行名称" prop="fullAccount">
              <el-input v-model="basicsForm.fullAccount" placeholder="请输入完整开户行名称"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="员工总人数">
              <el-input v-model="basicsForm.k1" placeholder="请输入员工总人数" oninput="value=value.replace(/[^\d]/g,'')"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="管理人员数">
              <el-input v-model="basicsForm.k2" placeholder="请输入管理人员数" oninput="value=value.replace(/[^\d]/g,'')"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="销售人员数">
              <el-input v-model="basicsForm.k3" placeholder="请输入销售人员数" oninput="value=value.replace(/[^\d]/g,'')"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="研发人员数">
              <el-input v-model="basicsForm.k4" placeholder="请输入研发人员数" oninput="value=value.replace(/[^\d]/g,'')"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="品管人员数">
              <el-input v-model="basicsForm.k5" placeholder="请输入品管人员数" oninput="value=value.replace(/[^\d]/g,'')"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="厂房总面积(m²)">
              <el-input v-model="basicsForm.k6" placeholder="请输入厂房总面积" oninput="value=value.replace(/[^\d]/g,'')"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="车间数量">
              <el-input v-model="basicsForm.k7" placeholder="请输入车间数量" oninput="value=value.replace(/[^\d]/g,'')"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="车间工人总数量">
              <el-input v-model="basicsForm.k8" placeholder="请输入车间工人总数量" oninput="value=value.replace(/[^\d]/g,'')"/>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="设备清单">
              <el-input v-model="basicsForm.k9" placeholder="请输入设备清单" type="textarea"/>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="经营类目/大类" prop="category1">
              <el-input v-model="basicsForm.category1" placeholder="所属行业" type="textarea"/>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="经营类目/中类" prop="category2">
              <el-input v-model="basicsForm.category2" placeholder="主要营业范围" type="textarea"/>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="经营类目/小类" prop="category3">
              <el-input v-model="basicsForm.category3" placeholder="与沃莱迪生产经营挂钩的产品" type="textarea"/>
            </el-form-item>
          </el-col>

        </el-row>

        <div v-for="(item, index) in certifications" :key="index">
          <certification :certifications='certifications' :index="index"></certification>
        </div>

        <!--<el-col :span="11">
          <div class="el-icon-plus add-box" @click="addFromBox"></div>
        </el-col>-->
        <el-col :span="24">
          <div class="btn-box">
            <el-button @click="submitLogin">提交</el-button>
            <el-button @click="submitLogin">暂存</el-button>
          </div>
        </el-col>
      </el-form>

    </el-card>

    <qq-footer></qq-footer>

  </div>
</template>
<script>
import NavBox from '@/components/common/navBox'
import certification from '@/components/common/certification'
import QqFooter from '@/components/common/qqFooter'

export default {
  components: {
    QqFooter,
    NavBox,
    certification
  },
  data () {
    var checkMobile = (rule, value, cb) => {
      const regMobile = /^\d+$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的数字'))
    }
    return {
      options: [
        {
          value: 'TRADERS',
          label: '贸易商'
        }, {
          value: 'MANUFACTURER',
          label: '生产制造商'
        }, {
          value: 'COMPLETE',
          label: '咨询服务商'
        }, {
          value: 'LOGISTICS',
          label: '物流运输商'
        }, {
          value: 'MAINTAIN',
          label: '维修供应商'
        }, {
          value: 'PROJECT',
          label: '工程建设类'
        },{
          value: 'CONSULTATION',
          label: '服务（咨询）供应商'
        },
      ],
      certifications: [
        {
        hasModify: false,
        name: '商业通用条款',
        brief: '签字盖章上传',
        required: '<span style="color: red">通用条款/需盖章 (必填)</span>',
        download: '',
        filename: '沃莱迪供应商通用条款.doc',
        md5: [],
        fileList: []
      }, {
        hasModify: false,
        name: '廉洁协议',
        brief: '签字盖章上传',
        required: '<span style="color: red">廉洁协议/需盖章 (必填)</span>',
        download: '',
        filename: '廉洁协议.doc',
        md5: [],
        fileList: []
      }, {
        hasModify: false,
        name: '营业执照',
        brief: '营业执照上传',
        required: ' <span style="color: red">(必填)</span>',
        md5: [],
        fileList: []
      }, {
        hasModify: false,
        name: '质量管理体系证书',
        brief: '上传质量管理体系证书',
        required: ' <span style="color: red">(生产制造商必填)</span>',
        md5: [],
        fileList: []
      }, {
        hasModify: false,
        name: '环境/安全体系/生产区域照片',
        brief: '上传环境/安全体系/生产区照片/仓储',
        required: ' <span style="color: red">(生产制造商必填)</span>',
        download: '',
        filename: '',
        md5: [],
        fileList: []
      }, {
        hasModify: false,
        name: '售后服务承诺',
        brief: '上传售后服务承诺',
        required: ' <span style="color: red">(必填)</span>',
        download: '',
        filename: '',
        md5: [],
        fileList: []
      }, {
        hasModify: false,
        name: '专利证书',
        brief: '上传公司专利认证',
        download: '',
        filename: '',
        md5: [],
        fileList: []
      }, {
        hasModify: false,
        name: '产品授权资质或特殊经营/生产许可证',
        brief: '上传产品授权资质或特殊经营/生产许可证',
        download: '',
        filename: '',
        md5: [],
        fileList: []
      }],
      dialogImageUrl: '',
      dialogVisible: false,
      activeIndex: 1,
      basicsForm: {},
      rules: {
        name: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        contacts: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          },
          {
            min: 11,
            max: 11,
            message: '请输入合法的手机号',
            trigger: 'blur'
          },
          {
            validator: checkMobile,
            trigger: 'blur'
          }
        ],
        phone2: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          },
          {
            min: 11,
            max: 11,
            message: '请输入合法的手机号',
            trigger: 'blur'
          },
          {
            validator: checkMobile,
            trigger: 'blur'
          }
        ],
        contactPerson: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        contactPerson2: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        mailbox: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        address: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        socialCode: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        capital: [
          {
            required: true,
            message: '注册资本',
            trigger: 'blur'
          },
          {
            validator: checkMobile,
            trigger: 'blur'
          }
        ],
        site: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        addvalidity: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        editvalidity: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        bank: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        account: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        fullAccount: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        category1: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        category2: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        category3: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        enumType: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }]
      },
      distId: '',
      tempId: '',
      supplierId: '',
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      editValidityOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now()
        }
      },
      formList: {}
    }
  },
  created () {
    this.gettempList()
    this.certifications[0].download = `${this.getaway.redirect}srm/file/b089122d849c1bfbd9f182e7e375e25e/download`
    this.certifications[1].download = `${this.getaway.redirect}srm/file/8fd58633a3223866b8f88ff11b59bf3a/download`
  },
  mounted () {
  },
  methods: {
    async gettempList () {
      const res = await this.axios.get(`${this.getaway.redirect}srm/templates/getTemplateByKey?keyword=SUPPLIER_TEMPLATE`)
      this.tempList = res.data
      this.supplierId = res.data[0].id
    },

    // 提交表单信息
    async submitLogin () {
      // if (this.basicsForm.enumType === 'TRADERS' || this.basicsForm.enumType === 'MANUFACTURER') {
      if (this.basicsForm.enumType === 'TRADERS') {
        if (this.certifications[0].md5.length === 0 || this.certifications[1].md5.length === 0 || this.certifications[2].md5.length === 0 || this.certifications[5].md5.length === 0) {
          this.$message({
            message: '请完善必填资料!',
            type: 'error'
          })
          return
        }
      } else if (this.certifications[0].md5.length === 0 || this.certifications[1].md5.length === 0 || this.certifications[2].md5.length === 0 || this.certifications[3].md5.length === 0 || this.certifications[4].md5.length === 0 || this.certifications[5].md5.length === 0) {
        this.$message({
          message: '请完善必填资料!',
          type: 'error'
        })
        return
      }
      if (this.basicsForm.contactPerson === this.basicsForm.contactPerson2 || this.basicsForm.phone === this.basicsForm.phone2) {
        return this.$message.error('业务联系人/业务联系电话与主管联系人/主管联系电话不能一样')
      }
      this.$refs.addFormRef.validate(async (valid) => {
        if (valid) {
          for (let i = 0; i < this.certifications.length; i++) {
            const md5List = this.certifications[i].md5
            if (md5List) {
              this.certifications[i].md5 = md5List.join(',')
            }
            delete (this.certifications[i].fileList)
            delete (this.certifications[i].hasModify)
            delete (this.certifications[i].download)
            delete (this.certifications[i].filename)
            delete (this.certifications[i].required)
          }
          this.formList = {
            addSupplierTemplateDto: {
              json: JSON.stringify(this.basicsForm),
              srmSupplierId: this.distId,
              srmTemplateId: this.supplierId,
            },
            batchSupplierLicense: {
              licenseList: this.certifications,
            },
            supplierDto: {
              contacts: this.basicsForm.contacts,
              address: this.basicsForm.address,
              capital: this.basicsForm.capital,
              phone: this.basicsForm.phone,
              name: this.basicsForm.name,
              mailbox: this.basicsForm.mailbox,
              enumType: this.basicsForm.enumType,
              category3: this.basicsForm.category3,
              srmUserId: sessionStorage.getItem('usreId')
            }
          }
          await this.axios
            .post(`${this.getaway.redirect}srm/suppliers/register`, this.formList)
            .then((res) => {
              if (res.code === '0' && res.data) {
                this.$message({
                  message: '提交成功',
                  type: 'success'
                })
                this.$router.push('/achieve')
                sessionStorage.removeItem('usreId')
              } else {
                location.reload()
              }
            })
        } else {
          return false
        }
      })

      /*const a = 1
      if (a) {
        return
      }
      const next = {
        contacts: this.basicsForm.contacts,
        address: this.basicsForm.address,
        capital: this.basicsForm.capital,
        phone: this.basicsForm.phone,
        name: this.basicsForm.name,
        mailbox: this.basicsForm.mailbox,
        enumType: this.basicsForm.enumType,
        category3: this.basicsForm.category3,
        srmUserId: sessionStorage.getItem('usreId')
      }
      if (this.basicsForm.contactPerson === this.basicsForm.contactPerson2 || this.basicsForm.phone === this.basicsForm.phone2) {
        return this.$message.error('业务联系人/业务联系电话与主管联系人/主管联系电话不能一样')
      }
      this.$refs.addFormRef.validate(async (valid) => {
        if (this.basicsForm.enumType === 'TRADERS' || this.basicsForm.enumType === 'MANUFACTURER') {
          if (this.certifications[0].md5.length === 0 || this.certifications[1].md5.length === 0 || this.certifications[2].md5.length === 0 || this.certifications[5].md5.length === 0) {
            this.$message({
              message: '请完善必填资料!',
              type: 'error'
            })
            return
          }
        } else if (this.certifications[0].md5.length === 0 || this.certifications[1].md5.length === 0 || this.certifications[2].md5.length === 0 || this.certifications[3].md5.length === 0 || this.certifications[4].md5.length === 0 || this.certifications[5].md5.length === 0) {
          this.$message({
            message: '请完善必填资料!',
            type: 'error'
          })
          return
        }
        if (valid) {
          await this.axios
            .post(`${this.getaway.redirect}srm/suppliers`, next)
            .then((res) => {
              if (res.code === '0') {
                this.distId = res.data.id
                this.submitTemp()
              }
            })
        } else {
          return false
        }
      })*/
    },

    // 提交表单信息
    /*async submitTemp () {
      const fromJson = this.basicsForm
      const dist = {
        json: JSON.stringify(fromJson),
        srmTemplateId: this.supplierId,
        srmSupplierId: this.distId
      }
      await this.axios
        .post(`${this.getaway.redirect}srm/supplier_templates`, dist)
        .then((res) => {
          if (res.code === '0') {
            this.tempId = res.data.id
            this.qualificationSub()
          }
        })
    },

    async qualificationSub () {
      for (let i = 0; i < this.certifications.length; i++) {
        this.certifications[i].md5 = this.certifications[i].md5.join(',')
        delete (this.certifications[i].fileList)
        delete (this.certifications[i].hasModify)
        delete (this.certifications[i].download)
        delete (this.certifications[i].filename)
        delete (this.certifications[i].required)
      }
      const auditlist = {
        licenseList: this.certifications,
        srmSupplierId: this.distId
      }

      await this.axios
        .post(`${this.getaway.redirect}srm/supplier_licenses/addBatch`, auditlist)
        .then((res) => {
          if (res.code === '0') {
            this.$message({
              message: '提交成功',
              type: 'success'
            })
            this.$router.push('/achieve')
          }
        })
    },*/

    // 点击按钮新增表单
    addFromBox () {
      const addBox = {
        hasModify: true,
        md5: [],
        fileList: []
      }
      this.certifications.push(addBox)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-step__head.is-success {
  color: #3150E9;
}

/deep/ .el-textarea__inner {
  height: 120px;
}

/deep/ .el-step__title.is-success {
  color: #3150E9;
}

/deep/ .el-step__head.is-success {
  border-color: #3150E9;
}

.prompt {
  margin: 10px auto;
  font-size: 14px;
  color: #ec4832;
  padding: 5px;
  text-align: center;
  width: 70%;
  background-color: #f2f6f9;
}

/deep/ .select-box {
  .el-select {
    width: 100%;
  }
}

/deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100%;
}

.box {
  width: 1200px;
  margin: 20px auto;
  box-sizing: border-box;
  text-align: right;
  color: #ccc;

  > i {
    cursor: pointer;
  }
}

.el-card {
  width: 80%;
  margin: 0 auto;
}

.el-steps {
  width: 80%;
  margin: 20px auto;
}

.form-box {
  width: 80%;
  margin: 0 auto;
}

.note {
  width: 50%;
  margin-right: 20px;
}

/deep/ .verify {
  position: relative;

  .el-input__inner {
    width: 60%;
  }

  .pic {
    position: absolute;
    top: 0;
    right: 22px;
    width: 120px;
    height: 100%;
    border: 1px solid #dcdfe6;
  }
}

.btn-box {
  display: block;
  margin: 20px auto;
  text-align: center;
}

.note-box {
  width: 40%;
  padding: 12px 0;
}

.add-box {
  width: 100%;
  height: 188px;
  line-height: 188px;
  text-align: center;
  font-size: 158px;
  color: #CCCCCC;
  margin-top: 40px;
}
</style>
